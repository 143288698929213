import { Message, MessageBox } from "element-ui";

/**
 * 消息提示框
 * @param {*} param0 
 */
export const $message = ({ type, message , duration = 1500, callback = () => {} }) => {
    Message({
        type, //消息类型  success/warning/info/error	
        message, //消息文字
        duration, // 显示时间
        onClose: callback //关闭时的回调函数
    })
}
export const $MessageBox = (a) => {
    MessageBox({
        type: "warning",
        message: '是否继续删除',
        showCancelButton: true,
        showConfirmButton: true,
        callback(actions) {
            if (actions == "confirm") {
                a();
            }
        }
    })
}