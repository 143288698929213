import axios from "axios";
import obj from './config';
import router from '@/router';
import { $message } from "@/config/utils"
import store from "@/store"

const $axios = axios.create({
    baseURL: obj.baseURL,
    timeOut: 5000,

})

$axios.interceptors.request.use(function(config) {
    //在发送请求前 
    if (config.url != "/api/user/login") config.headers.token = store.getters.TOKEN;
    return config;
}, function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// // 添加响应拦截器
$axios.interceptors.response.use(function(response) {
    if (response.status == 200) return response.data;
    let message;
    let callback;
    if (response.status == 401) {
        message = response.msg || "未传递token"
        callback = () => {
            router.push("/login");
        }
    } else if (response.status == 403) {
        message = response.msg
        this.$message({ type: "warning", message: message })
    } else {
        message = response.msg || '服务器出错！！！';
    }
    $message({
        type: "error",
        message,
        callback
    });
    // 对响应数据做点什么
}, function(error) {
    // 对响应错误做点什么
    $message({
        type: "error",
        message: "服务器繁忙，请稍后再试！！" + error
    })
    return Promise.reject(error);
});
export default $axios //导出请求实例