
import {getPricePackage,getPriceInterval} from '@/request/price'
//用户
export default {
    state() {
        return {
            packageList:[],
            priceList:[],
           
        }
       
    },
    mutations: {
       async setPackageList(state) {
            state.packageList =await getPricePackage()
        },
        async getpriceList(state) {
            state.priceList =await getPriceInterval()
        },
       
    },
    modules: {}
}