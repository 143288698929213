import router from "@/router"
let userInfo = sessionStorage.getItem("userInfo") ? JSON.parse(sessionStorage.getItem("userInfo")) : {};
let username = userInfo.username || '';
let mobile = userInfo.mobile || '';
let is_rz = userInfo.is_rz || '';
let token = userInfo.token || '';
let avatar = userInfo.avatar || '';
let nickname = userInfo.nickname || '';
let email = userInfo.email || ''
if (avatar.charAt(0) == '/') {
    avatar = 'https://itf.101fenfa.com' +avatar
}
    //用户
export default {
    state() {
        return {
            username, //用户名
            token, //token
            mobile,//手机号
            is_rz,//是否实名
            avatar,//头像
            nickname,//昵称
            email,
        }
    },
    getters: {
        TOKEN: state => state.token,
    },
    mutations: {
        //修改用户名
        setUserName(state, v) {
            state.username = v
        },
        //设置token信息
        setToken(state, v) {
            state.token = v;
        },
        //设置手机号
        setMobile(state, v) {
            state.mobile = v;
        },
        //设置是否实名认证
        setIsrz(state, v) {
            state.is_rz = v;
        },
        //设置用户头像
        setAvatar(state, v) {
            state.avatar = v;
        },
        setNickname(state, v) {
            state.nickname = v;
        },
        setEmail(state, v) {
            state.email = v;
        },
        quit(state) {
            state.username = ''
            state.token = ''
            state.mobile = ''
            state.avatar = ''
            state.nickname = ''
            state.is_rz = ''
            state.email=''
            sessionStorage.removeItem("userInfo")
            router.push('/home').catch(err=>{
              
            })
        }
    },
    actions: {
        async setIsrz({ commit }, v) {
            await commit('setIsrz', v)
        },
        async setAvatar({ commit }, v) {
            await commit('setAvatar', v)
        },
        async setNickname({ commit }, v) {
            await commit('setNickname', v)
        },
        async setUserName({ commit }, v) {
            await commit('setUserName', v)
        },

    },
    modules: {}
}