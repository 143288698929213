import Vue from "vue";
// import { $message } from "element-ui";
import store from '@/store'
import VueRouter from "vue-router";
//引入nprogress
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' //这个样式必须引入
// 简单配置
NProgress.inc(0.2)
NProgress.configure({easing: 'ease', speed: 500, showSpinner: false})

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'hash',
    routes: [{
        path: '/',
        redirect: '/home',
        meta: {
            title: 'zhuye'
        },
        component: () =>
            import('@/asck/IndexVue.vue'),
        children: [{
            path: 'home',
            meta: {
                title: '首页',
              
            },
            component: () =>
                import('@/pages/HomeIndex.vue'),

        }, {
            path: 'getuuid',
            meta: {
                title: '获取UDID',

            },
            component: () =>
                import("@/pages/getUDID/GetUuid.vue"),

        },{
            path: '/udidshow',
            meta: {
                title: '获取本机UDID',

            },
            component: () =>
                import("@/pages/getUDID/UdidShow.vue"),
        }, {
            path: 'signature',
            meta: {
                title: '签名工具',

            },
            component: () =>
                import('@/pages/tool/ToolVue.vue'),

        },
        {
            path: '/mobileudid',
            meta: {
                title: '获取本机UDID',

            },
            component: () =>
                import("@/pages/getUDID/MobileView.vue"),
        }, {
            path: 'publish',
            meta: {
                title: '分发',

            },
            component: () =>
                import('@/pages/publish/PublishVue.vue'),
            beforeEnter: (to, from, next) => {
                let isLogin = store.getters.isLogin;
                if (isLogin) { //判断是否登录
                    next()
                    return
                } else {
                    next('/login')
                    return
                }
            }
        },{
            path: 'autoupdate',
            meta: {
                title: '自动更新',

            },
            component: () =>
                import('@/pages/autoupdata/UploadFile.vue'),
            beforeEnter: (to, from, next) => {
                let isLogin = store.getters.isLogin;
                if (isLogin) { //判断是否登录
                    next()
                    return
                } else {
                    next('/login')
                    return
                }
            }
        },{
            path: 'certificate',
            meta: {
                title: '证书检测',

            },
            component: () =>
                import('@/pages/certificate/CertificateCheck.vue'),
        },{
            path: 'managecertificate',
            meta: {
                title: '管理证书',

            },
            component: () =>
                import('@/pages/certificate/ManageCert.vue'),
                beforeEnter: (to, from, next) => {
                    next() //去登陆
                }
        }, {
            path: '/intval',
            meta: {
                title: '修改个人信息',

            },
            component: () =>
                import('@/pages/IntVal/IntVal.vue'),
            beforeEnter: (to, from, next) => {
                let isLogin = store.getters.isLogin;
                if (isLogin) { //判断是否登录
                    next()
                } else {
                    next('/login')
                }
            }
        }, {
            path: 'detect',
            meta: {
                title: '下载管理',

            },
            component: () =>
                import("@/pages/AppLoad/DetectVue.vue"),
            beforeEnter: (to, from, next) => {
                let isLogin = store.getters.isLogin;
                if (isLogin) { //判断是否登录
                    next()
                } else {
                    next('/login') //去登陆
                }
            }
        }, {
            path: 'getid',
            meta: {
                title: '管理UDID',

            },
            component: () =>
                import("@/pages/UID/ManageId.vue"),
            beforeEnter: (to, from, next) => {
                let isLogin = store.getters.isLogin;
                if (isLogin) { //判断是否登录
                    next()
                } else {
                    next('/login') //去登陆
                }
            }
        }, {
            path: 'price',
            meta: {
                title: '支付管理',

            },
            component: () =>
                import("@/pages/price/PriceVue.vue"),
            beforeEnter: (to, from, next) => {
                let isLogin = store.getters.isLogin;
                if (isLogin) { //判断是否登录
                    next()
                } else {
                    next('/login') //去登陆
                }
            }
        }, {
            path: 'realname',
            meta: {
                title: '实名认证',

            },
            component: () =>
                import("@/pages/RealName.vue"),
            beforeEnter: (to, from, next) => {
                let isLogin = store.getters.isLogin;
                let isrz = store.getters.isrz
                if (isLogin) { //判断是否登录
                    next()
                } else if (!isLogin) {
                    next('/login') //去登陆
                } else if (isrz) {
                    next('/notfound')
                }
            }
        },
        {
            path: '/appmanagement',
            meta: {
                title: '应用管理',

            },
            component: () =>
                import("@/pages/AppManagement/AppManagement.vue"),
            beforeEnter: (to, from, next) => {
                let isLogin = store.getters.isLogin;
                if (isLogin) { //判断是否登录
                    next()
                } else {
                    next('/login') //去登陆
                }
            }
        },
        {
            path: '/pricepay',
            meta: {
                title: '支付服务',

            },

            component: () =>
                import('@/pages/price/PricePay.vue'),
            beforeEnter: (to, from, next) => {
                let isLogin = store.getters.isLogin;
                if (isLogin) { //判断是否登录
                    next()
                } else {
                    next('/login')
                }
            }
        },
        {
            path: '/pay',
            meta: {
                title: '支付',

            },
            component: () =>
                import('@/pages/price/WechatPay.vue'),
            beforeEnter: (to, from, next) => {
                let isLogin = store.getters.isLogin;
                if (isLogin) { //判断是否登录
                    next()
                } else {
                    next('/login')
                }
            }
        },
        {
            path: 'audit',
            meta: {
                title: '审核规范',

            },
            component: () =>
                import('@/pages/aboutus/AuditSpec.vue')
        },
        {
            path: 'privacypolicy',
            meta: {
                title: '隐私政策'
            },
            component: () =>
                import('@/pages/aboutus/PrivacyPolicy')
        },
        {
            path: '/aboutus',
            meta: {
                title: '关于我们'
            },
            component: () =>
                import('@/pages/aboutus/AboutUs.vue'),
        },
        {
            path: '/service',
            meta: {
                title: '服务协议'
            },
            component: () =>
                import('@/pages/aboutus/ServiceAgreement.vue'),
        }, {
            path: '/mycenter',
            meta: {
                title: '个人中心'
            },
            component: () =>
                import("@/pages/mycenter/MyCenter.vue"),
            beforeEnter: (to, from, next) => {
                let isLogin = store.getters.isLogin;
                if (isLogin) { //判断是否登录
                    next()
                } else {
                    next('/login') //去登陆
                }
            }
        },
        ]
    },
    {
        path: '/login',
        meta: {
            title: '登录'
        },
        component: () =>
            import('@/asck/MyLogin.vue')

    }, {
        path: '*',
        meta: {
            title: 'notfound'
        },
        component: () =>
            import('@/asck/NotFound.vue')

    },
    {
        path: '/register',
        meta: {
            title: 'register'
        },
        component: () =>
            import('@/asck/RegisterVue.vue')

    },{
        path: '/forgetpassword',
        meta: {
            title: '忘记密码',
        },
        component: () =>
            import("@/asck/ForgetPwd.vue"),

    },
    ]
});
// 当路由请求之前
router.beforeEach((to, from, next) => {
    // 每次切换页面时，调用进度条
    NProgress.start();
    // 这个一定要加，没有next()页面不会跳转的
    next();
});
// 当路由请求之后：关闭进度条
router.afterEach(() => {
    // 在即将进入新的页面组件前，关闭掉进度条
    NProgress.done()
})



export default router