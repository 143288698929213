
let isenroll = sessionStorage.getItem("isenroll") ?sessionStorage.getItem("isenroll") : '';
 isenroll = isenroll || 1
//用户
export default {
    state() {
        return {
          isenroll
        }
    },
    mutations: {
        setIsenroll(state, v) {
            state.isenroll = v
        },
    },
    modules: {}
}