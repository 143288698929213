import Vue from "vue";
import Vuex from "vuex";
import user from './modules/user'
import login from "./modules/login";
import price from './modules/price';
Vue.use(Vuex);
let userPay = sessionStorage.getItem("userPay") ? JSON.parse(sessionStorage.getItem("userPay")) : '';
let erweima = sessionStorage.getItem("erweima") ? sessionStorage.getItem("erweima") : '';
let ispay = sessionStorage.getItem("ispay") ? sessionStorage.getItem("ispay") : '';
export default new Vuex.Store({
    state: {
        userPay,
        erweima,
        ispay
    },
    getters: {
        isLogin: state => state.user.username.length > 0,
    },
    mutations: {
        setuserPay(state, v) {
            state.userPay = v
        },
        seterweima(state, v) {
            state.erweima = v
        },
        ispay(state, v) {
            state.ispay = v
        }
    },
    modules: {
        user,
        login,
        price,


    }
})