import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import 'element-ui/lib/theme-chalk/index.css'
import './directive/dialogdrog'//全局引入v-dialogDrag自定义指令
global.Buffer = global.Buffer || require('buffer').Buffer
const bus = new Vue()

Vue.prototype.$bus = bus;
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueClipboard)
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')