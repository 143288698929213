<template>
  
  <div id="app" style="width: 100vw;overflow: hidden; box-sizing: border-box;">

    <transition name="fade-transform" mode="out-in">
      <router-view></router-view>
  </transition>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
  
  },
}
</script>

<style>
html{
  overflow-x: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 375px;
}
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

/* 
enter定义进入过渡的开始状态
 */
.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}
/* 
leave-to离场动画结束后的状态
 */
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
