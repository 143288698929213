import $axios from "@/config/http";
//获取支付管理列表
export async function getPrice(page) {
    return await $axios.post('/api/userlist/pay_list', page)
}
//获取套餐列表
export async function getPricePackage() {
    return await $axios.get('/api/userorder/index')
}
//微信支付
export async function getWechatPay(data) {
    return await $axios.post('/api/userorder/wechat', data)
}
//支付宝支付
export async function getAlipay(data) {
    return await $axios.post('/api/userorder/alipay', data)
}
//获取价格区间列表
export async function getPriceInterval() {
    return await $axios.get('/api/userorder/kf_list')
}