let baseURL; //服务器地址
let hostName; //主机名
let port;
if (process.env.NODE_ENV == "development") {
    baseURL = "https://itf.101fenfa.com";
    port = "5000";
    hostName = "http://localhost:5000";
} else {
    baseURL = "https://itf.101fenfa.com"
    port = "5000";
    hostName = "http://122.90.114.56";
}


export default {
    baseURL,
    hostName,
    port
}